import { Component } from '@angular/core';
import { DialogInput } from './dialog.input';
import { DialogOptionInput, OptionValue } from './dialog.optioninput';
import { DialogTextInput } from './dialog.textinput';
import { DialogIntervalInput, LbtNumberInterval } from './dialog.intervalinput';

export { DialogInput, DialogOptionInput, OptionValue, DialogTextInput, DialogIntervalInput, LbtNumberInterval };

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.css',
  host: {'[class.showDialog]': 'dialogShow'}
})
export class DialogComponent {
  private promise: Promise<any[]>;
  private resolve: (outputs: any[]) => void;
  private reject: (reason?: any) => void;
  protected dialogTitle: string;
  protected dialogMessage: string;
  protected dialogInputs: DialogInput[];
  protected dialogStyle: string;
  protected acceptButtons: string[];
  protected cancelButtons: string[];
  protected dialogShow = false;
  protected output: Map<string, string|number>;
  protected text: boolean = false;

  private static mainDialog: DialogComponent|null = null;
  public static registerDialog(dialog: DialogComponent): boolean {
    if (DialogComponent.mainDialog == null && dialog != undefined) {
      DialogComponent.mainDialog = dialog;
      return true;
    }
    console.error("registerDialog failed due to a dialog already being registered");
    return false;
  }

  public static unregisterDialog(dialog: DialogComponent) {
    if (DialogComponent.mainDialog == dialog) {
      DialogComponent.mainDialog = null;
      return true;
    }
    console.error("unregisterDialog failed due to a different dialog already being registered");
    return false;
  }

  // TODO: implement requests queue
  public static showDialog(title: string, message: string, style: string = "", acceptButtons: string[] = ['ACEPTAR'], cancelButtons: string[] = ['CANCELAR']): Promise<any[]> {
    if (DialogComponent.mainDialog) {
      return DialogComponent.mainDialog.showDialog(title, message, style, acceptButtons, cancelButtons);
    }
    console.error("showDialog called without a registered main dialog");
    return new Promise((_, reject) => reject());
  }

  public static showInfoDialog(title: string, message: string, style: string = ""): Promise<any[]> {
    if (DialogComponent.mainDialog) {
      return DialogComponent.mainDialog.showDialog(title, message, style, ['ACEPTAR'], []);
    }
    console.error("showInfoDialog called without a registered main dialog");
    return new Promise((_, reject) => reject());
  }

  public static showInputsDialog(title: string, message: string, inputs: DialogInput[], style: string = "", acceptButtons: string[] = ['ACEPTAR'], cancelButtons: string[] = ['CANCELAR']): Promise<any[]> {
    if (DialogComponent.mainDialog) {
      return DialogComponent.mainDialog.showInputsDialog(title, message, inputs, style, acceptButtons, cancelButtons);
    }
    console.error("showInputsDialog called without a registered main dialog");
    return new Promise((_, reject) => reject());
  }

  public static showLoadingDialog(title: string, message: string, style: string = ""): Promise<any[]> {
    if (DialogComponent.mainDialog) {
      return DialogComponent.mainDialog.showDialog(title, message, style, [], ['CANCELAR']);
    }
    console.error("showLoadingDialog called without a registered main dialog");
    return new Promise((_, reject) => reject());
  }

  public static showError(errorId: string): Promise<any[]> {
    if (DialogComponent.mainDialog) {
      return DialogComponent.mainDialog.showError(errorId);
    }
    console.error("showError called without a registered main dialog");
    return new Promise((_, reject) => reject());
  }

  public static hideDialog(): void {
    if (DialogComponent.mainDialog) {
      DialogComponent.mainDialog.dialogShow = false;
    }
  }

  private showDialog(title: string, message: string, style: string, acceptButtons: string[], cancelButtons: string[]): Promise<any[]> {
    this.initDialog(title, message);
    this.dialogInputs = [];
    this.dialogStyle = style;
    this.acceptButtons = acceptButtons;
    this.cancelButtons = cancelButtons;
    return this.enableDialog();
  }

  private showInputsDialog(title: string, message: string, inputs: DialogInput[], style: string, acceptButtons: string[], cancelButtons: string[]): Promise<any[]> {
    this.initDialog(title, message);
    this.dialogInputs = inputs;
    this.dialogStyle = style;
    this.acceptButtons = acceptButtons;
    this.cancelButtons = cancelButtons;
    // reverse traverse the array to set next options for cascade values
    let next: DialogOptionInput|null = null;
    for (let i = this.dialogInputs.length -1; i >= 0; --i) {
      let input = this.dialogInputs[i];
      if (input instanceof DialogOptionInput) {
        input.setNext(next);
        next = input;
      }
    }
    return this.enableDialog();
  }

  private showError(errorId: string): Promise<any[]> {
    let message = '';
    switch (errorId)
    {
    // VOUCHERS
    // TODO: get translations from loc db
    case 'ERR_TICKET_INVALID':
      message = 'Este ticket ya ha sido cerrado.';
      break;
    case 'ERR_CODE_INVALID':
      message = 'Código inválido.';
      break;
    case 'ERR_CODE_CONSUMED':
      message = 'Vale ya usado.';
      break;
    case 'ERR_CODE_EXPIRED':
      message = 'Vale fuera de fecha de validez.';
      break;
    case 'ERR_INSUFICIENT_POINTS':
      message = 'El usuario no tiene suficientes puntos.';
      break;
    // GLOBAL
    case 'ERR_SYSTEM':
      message = 'Error de servidor, por favor, inténtelo más tarde.';
      break;
    default:
      message = errorId;
    }
    this.showDialog('Error', message, 'info error', ['ACEPTAR'], []);

    return this.enableDialog();
  }

  protected isValid(): boolean {
    return (this.dialogInputs == null || this.dialogInputs.findIndex(i => i.isValid() == false) < 0);
  }

  protected acceptDialog(button: string) {
    
    let outputs = [];
    outputs['button'] = button;
    this.dialogInputs?.forEach(
      i => {
        if (i instanceof DialogTextInput) {
          outputs[i.name] = i.value.trim();
        }
        else if (i instanceof DialogOptionInput) {
          outputs[i.name] = i.selected;
        }
        else if (i instanceof DialogIntervalInput) {
          outputs[i.name] = i.interval;
        }
      }
    );
    this.resolve(outputs);
    this.dialogInputs = [];
    this.acceptButtons = [];
    this.cancelButtons = [];
    this.dialogShow = false;
  }

  protected cancelDialog(button: string) {
    this.reject(button);
    this.dialogInputs = [];
    this.acceptButtons = [];
    this.cancelButtons = [];
    this.dialogShow = false;
  }

  private initDialog(title: string, message: string) {
    this.dialogTitle = title;
    this.dialogMessage = message;
    this.dialogStyle = "";
  }

  private enableDialog(): Promise<any[]> {
    this.dialogShow = true;
    this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    return this.promise;
  }

  protected getTextInput(input: DialogInput): DialogTextInput|null {
    return input instanceof DialogTextInput ? input : null;
  }

  protected getOptionInput(input: DialogInput): DialogOptionInput|null {
    return input instanceof DialogOptionInput ? input : null;
  }

  protected getIntervalInput(input: DialogInput): DialogIntervalInput|null {
    return input instanceof DialogIntervalInput ? input : null;
  }
}
