export class StringUtils {

  public static levenshteinDistance(s: string, t: string): number {
    if (!s.length) return t.length;
    if (!t.length) return s.length;
    let arr:number[][] = [];
    for (let i = 0; i <= t.length; ++i) {
      arr[i] = [i];
      for (let j = 1; j <= s.length; ++j) {
        arr[i][j] = i === 0 ? j : Math.min(arr[i - 1][j] + 1, arr[i][j - 1] + 1, arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1));
      }
    }
    return arr[t.length][s.length];
  }
  
  public static closestMatch(s: string, a: [string, ...ReadonlyArray<string>]): [string, number] { // a must be a non empty string array
    let closest = a.find(t => t == s);
    if (closest != undefined) {
      return [closest, 0];
    }
    let min = Number.MAX_SAFE_INTEGER;
    for (const t of a) {
      const d = this.levenshteinDistance(s, t);
      if (d < min) {
        min = d;
        closest = t;
      }
    }
    return [closest, min];
  }
}