import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { WebService } from '../web.service';

import { LbtSelectValue } from '../shared/forms/lbt-select/lbt-select.component';
import { DateUtils, DateFormat } from '../shared/utils/dateUtils';
import { StringUtils } from '../shared/utils/stringUtils';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { HourlyOccupation, Reserve, ReserveOption, ReserveOptionValue, ReserveStatus } from '../../../../tpv/src/app/reserves/_data/_data';
import { Zone } from '../../../../tpv/src/app/stores/_data/zones';

@Component({
  selector: 'app-web-reserve-add',
  templateUrl: './web-reserve-add.component.html',
  styleUrl: './web-reserve-add.component.css'
})
export class WebReserveAddComponent implements OnInit, AfterViewInit {
  private ws = WebService.getInstance();
  @Input() command: string;
  @Input() urlCode: string;
  private selectedHourlyOccupation: HourlyOccupation;
  private interval: number;
  private selectedOptions: Map<string, number> = new Map();
  private replaceOptions: any[];
  private ref: number;
  protected minTotalPax: number = 0;
  protected minTotalAccepted: boolean = false;
  protected loggedIn: boolean = false;
  protected zones: Zone[];

  constructor(private router: Router) {}

  public ngOnInit(): void {
    const payload = this.ws.getTokenPayload();
    this.loggedIn = payload != null;
    if (this.loggedIn) {
      this.email = payload['email'];
      this.name = payload['name'];
      this.lastname = payload['lastname'];
      this.phone = payload['phone'];
    }
    this.ws.subscribeToZones(z => this.zones = z);
  }

  public ngAfterViewInit() {
    if (this.urlCode == null) {
      return;
    }
    switch (this.command) {
    case 'A':
    case 'a':
    case 'assign':
      setTimeout(() => this.assignReserve(), 100);    // let some time to grab translations
      break;
    case 'C':
    case 'c':
    case 'confirm':
      setTimeout(() => this.confirmReserve(), 100);
      break;
    case 'U':
    case 'u':
    case 'update':
      setTimeout(() => this.checkUpdateReserve(), 100);
      break;
    case 'X':
    case 'x':
    case 'cancel':
      setTimeout(() => this.checkCancelReserve(), 100);
      break;
    default:
      DialogComponent.showError(this.getTranslation('USER_RA_INVALID_ERR'));
      break;
    }
  }

  private assignReserve() {
    // check user is logged
    if (this.loggedIn == false) {
      DialogComponent.showError(this.getTranslation('USER_RA_UNLOGGED_ERR')).then(_ => this.router.navigate(['reserve']));
      return;
    }
    this.ws.assignReserve(this.urlCode.substring(1), 
      r => DialogComponent.showInfoDialog(this.getTranslation('USER_RA_ASSIGNED_TITLE'), this.getTranslation('USER_RA_ASSIGNED_MSG'))
        .then(_ => this.goToUser()),
      () => DialogComponent.showError(this.getTranslation('USER_RA_ASSIGNED_ERR')).then(_ => this.router.navigate(['reserve']))
    );
    this.urlCode = null;
  }

  private confirmReserve() {
    this.ws.confirmReserve(this.urlCode.substring(1), 
      r => DialogComponent.showInfoDialog(this.getTranslation('USER_RA_CONFIRMED_TITLE'), this.getTranslation('USER_RA_CONFIRMED_MSG') + r.getSummary()).then(_ => this.router.navigate(['reserve'])),
      () => DialogComponent.showError(this.getTranslation('USER_RA_CONFIRMED_ERR')).then(_ => this.router.navigate(['reserve']))
    );
    this.urlCode = null;
  }

  private checkUpdateReserve() {
    // check user is logged and matches reserve user
    if (this.loggedIn == false) {
      DialogComponent.showError(this.getTranslation('USER_RA_UNLOGGED_ERR')).then(_ => this.router.navigate(['reserve']));
      return;
    }
    this.ws.getReserve(this.urlCode.substring(1), this.urlCode[0],
      r1 => this.updateReserve(r1),
      code => {
        switch (code) {
        case 404:
          DialogComponent.showError(this.getTranslation('USER_RA_BADCODE_ERR')).then(_ => this.router.navigate(['reserve']));
          break;
        case 422:
          this.ws.getReserve(this.urlCode.substring(1), null,
            r2 => DialogComponent.showInfoDialog(this.getTranslation('USER_RA_UPDATED_TITLE'), this.getTranslation('USER_RA_UPDATED_MSG'), 'warning')
              .then(_ => this.updateReserve(r2))
          );
        default:
          DialogComponent.showError(this.getTranslation('USER_RA_UNKNOWN_ERR')).then(_ => this.router.navigate(['reserve']));
          break;
        }
      }
    );
  }

  private updateReserve(reserve: Reserve) {
    // check status!!!
    if (reserve.date < DateUtils.getTodayString() || (reserve.date == DateUtils.getTodayString() && reserve.start < DateUtils.getNow())) {
      DialogComponent.showError(this.getTranslation('USER_RA_DUE_ERR') + reserve.getSummary()).then(_ => this.router.navigate(['reserve']));
    }
    else if (reserve.status != ReserveStatus.Requested && reserve.status != ReserveStatus.Verified) {
      DialogComponent.showError(this.getTranslation('USER_RA_CONSUMED_ERR') + reserve.getSummary()).then(_ => this.router.navigate(['reserve']));
    } 
    else {
      // prefill form
      this.replaceDate = reserve.date;
      this.replaceSize = reserve.size;
      this.replaceStart = reserve.start;
      this.replaceDuration = reserve.duration;
      this.replaceComments = reserve.comments;
      this.replaceOptions = reserve.attributes;
      // start form population
      this.selectedZone = this.zones.find(z => z.id == reserve.zoneId);
    }
  }

  private checkCancelReserve() {
    this.ws.getReserve(this.urlCode.substring(1), this.urlCode[0],
      r1 => DialogComponent.showDialog(this.getTranslation('USER_RA_CANCEL_TITLE'), this.getTranslation('USER_RA_CANCEL_MSG', {"summary": r1.getSummary()}))
        .then(_ => this.cancelReserve(r1)),
      code => {
        switch (code) {
        case 404:
          DialogComponent.showError(this.getTranslation('USER_RA_BADCODE_ERR')).then(_ => this.router.navigate(['reserve']));
          break;
        case 422:
          this.ws.getReserve(this.urlCode.substring(1), null,
            r2 =>DialogComponent.showDialog(this.getTranslation('USER_RA_REUPDATE_TITLE'), this.getTranslation('USER_RA_REUPDATE_MSG', {"summary": r2.getSummary()}), 'warning')
              .then(_ => this.cancelReserve(r2))
          );
        default:
          DialogComponent.showError(this.getTranslation('USER_RA_UNKNOWN_ERR')).then(_ => this.router.navigate(['reserve']));
          break;
        }
      }
    );
  }

  private cancelReserve(reserve: Reserve) {
    if (reserve.status != ReserveStatus.Requested && reserve.status != ReserveStatus.Verified) {
      DialogComponent.showError(this.getTranslation('USER_RA_CONSUMED_ERR') + reserve.getSummary()).then(_ => this.router.navigate(['reserve']));
    }
    else {
      this.ws.cancelReserve(reserve.code, reserve.version, 
        r => DialogComponent.showInfoDialog(this.getTranslation('USER_RA_CANCELED_TITLE'), this.getTranslation('USER_RA_CANCELED_MSG') + r.getSummary()).then(_ => this.router.navigate(['reserve'])),
        () => DialogComponent.showError(this.getTranslation('USER_RA_UNKNOWN_ERR')).then(_ => this.router.navigate(['reserve'])));
    }
    this.urlCode = null;    
  }

  private _selectedZone: Zone = null;
  protected get selectedZone(): Zone {
    return this._selectedZone;
  }
  protected set selectedZone(zone: Zone) {
    if (this.isZoneActive(zone)) {
      this.ws.s();
      this._selectedZone = zone;
      if (zone) {
        this.date = null; // This will also cascade clear other fields
        const startDate = DateUtils.getTodayString();
        let date = DateUtils.getTodayDate();
        date.setDate(date.getDate() + (this.loggedIn ? 31 : 15));
        const endDate = DateUtils.date2IsoString(date);
        this.ws.getDailyOccupation(zone.id, startDate, endDate, 
          o => {
            this.dateValues = o.getDates().map(d => new LbtSelectValue(o.getOccupation(d).dayType == 'CLOSED' || o.getOccupation(d).dayType == 'PRIVATE' ? null : d, this.getOccupationSymbols(o.getOccupation(d).level) + '  ' + DateUtils.date2LocalString(DateUtils.parseMysqlDate(d), DateFormat.Medium)));
            if (!this.loggedIn) {
              this.dateValues.push(new LbtSelectValue(null, this.getTranslation('USER_RA_MORE_DATES')));
            }
            if (this.replaceDate != null) {
              this.date = this.replaceDate;
              this.replaceDate = null;
            }   
            this.ref = Date.now();
          }
        );
      }
    }
  }

  private _date: string = null;
  private replaceDate: string;
  protected get date(): string {
    return this._date;
  }
  protected set date(date: string) {
    if (date != this.date) {
      this.ws.t(1, Date.now() - this.ref);
      this._date = date;
      this.size = null; // This will also cascade clear other fields
      if (date != '' && this.selectedZone != null) {
        this.ws.getHourlyOccupation(this.selectedZone.id, date, this.urlCode?.substring(1),
          o => {
            this.selectedHourlyOccupation = o;
            this.interval = o.getTimetable().getInterval();
            this.sizeValues = Array.from(Array(this.getMaxSize(o)+1).keys()).slice(1).map(s => new LbtSelectValue(s, s.toString()));
            this.sizeValues.push(new LbtSelectValue(null, this.getTranslation('USER_RA_NO_MORE_SEATS')));
            if (this.replaceSize != null) {
              this.size = this.replaceSize;
              this.replaceSize = null;
            }   
            this.ref = Date.now();
          }
        );
      }
    }
  }
  protected dateValues: LbtSelectValue[];

  private _size: number = null;
  private replaceSize: number;
  protected get size(): number {
    return this._size;
  }
  protected set size(size: number) {
    if (size != this._size) {
      this.ws.t(0, Date.now() - this.ref);
      this._size = size;
      this.start = null;  // This will also cascade clear other fields
      this.startValues = size == null ? [] : this.selectedHourlyOccupation.getHours().slice(0, -1).map(h => this.getMaxDuration(this._date, h, this._size) > 0 ? new LbtSelectValue(h, DateUtils.formatTime(h)): new LbtSelectValue(null, DateUtils.formatTime(h) + '  ' + this.getTranslation('USER_RA_NO_MORE_HOURS')));
      if (this.replaceStart != null) {
        this.start = this.replaceStart;
        this.replaceStart = null;
      }   
    }
  }
  protected sizeValues: LbtSelectValue[];

  private _start: number = null;
  private replaceStart: number;
  protected get start(): number {
    return this._start;
  }
  protected set start(start: number) {
    if (start != this._start) {
      this.ws.t(2, Date.now() - this.ref);
      this._start = start;
      this.duration = null;  // This will also cascade clear other fields
      this.selectedOptions.clear();
      this.durationValues = start == null ? [] : Array.from(Array(this.getMaxDuration(this._date, this._start, this._size)/this.interval + 1).keys()).slice(2).map(i => new LbtSelectValue(i*this.interval, `${DateUtils.formatTime(this._start + (i * this.interval))}`));
      this.durationValues.push(new LbtSelectValue(null, this.getTranslation('USER_RA_NO_MORE_HOURS')));
      if (this.replaceDuration != null) {
        this.duration = this.replaceDuration;
        this.replaceDuration = null;
      }   
    }
  }
  protected startValues: LbtSelectValue[] = [];

  private _duration: number = null;
  private replaceDuration: number;
  protected get duration(): number {
    return this._duration;
  }
  protected set duration(duration: number) {
    if (duration != this._duration) {
      this.ws.t(3, Date.now() - this.ref);
      this._duration = duration;
      this.minTotalPax = 0;
      this.minTotalAccepted = false;
      this.selectedOptions.clear();
      if (this.replaceComments != null) {
        this.comments = this.replaceComments;
        this.replaceComments = null;
      }
      if (this.replaceOptions != null) {
        const options = this.getOptions();
        Object.keys(this.replaceOptions).forEach(
          k => {
            const option = options.find(o => k == o.getTag());
            if (option != undefined) {
              const value = option.getValues().find(v => this.replaceOptions[k] == v.getValue());
              if (value != undefined) {
                this.selectOptionValue(option, value);
              }
            }
          }
        );
      }
    }
  }
  protected durationValues: LbtSelectValue[] = [];

  protected isZoneActive(zone: Zone) {
    if (zone?.requirements) {
      return Object.keys(zone.requirements).findIndex(k => this[k] != undefined && this[k] != zone.requirements[k]) == -1;
    }
    return true;
  }

  protected isZoneSelected(zone: Zone) {
    return this.selectedZone && zone.id == this.selectedZone.id;
  }

  protected getOptions(): ReserveOption[] {
    const options: ReserveOption[] = this.selectedHourlyOccupation ? this.selectedHourlyOccupation.getOptions() : [];
    const result = options.filter(o => (o.getStart() == 0 && o.getEnd() == 0) || (this._start < o.getEnd() && this._start + this._duration > o.getStart()));
    return result;
  }

  protected isValueActive(v: ReserveOptionValue): boolean {
    const result = (v.getStart() == 0 && v.getEnd() == 0) || (this._start <= v.getStart() && this._start + this._duration >= v.getEnd());
    return result;
  }

  // return value status unless all possible values are error, then ensure one option gets to warning
  protected getValueStatus(option: ReserveOption, value: ReserveOptionValue) {
    if (this.isValueActive(value) && option.getValues().find(v => this.isValueActive(v) && (v.getStatus() != 'error')) == undefined) {
      return option.getValues().find(v => this.isValueActive(v) && (v.getLevel() < value.getLevel())) == undefined ? 'warn' : 'error';
    }
    return value.getStatus();
  }

  protected selectOptionValue(option: ReserveOption, value: ReserveOptionValue) {
    if (this.isValueActive(value) && this.getValueStatus(option, value) != 'error') {
      if (this.isValueSelected(option, value)) {
        if (!option.isRequired()) {
          this.selectedOptions.delete(option.getTag());
          this.minTotalPax -= value.getMinTotalPax();
        }
      }
      else {
        // remove first the current selected option
        const currentOptionValue = this.selectedOptions.get(option.getTag());
        if (currentOptionValue != undefined) {
          const currentValue = this.getOptions().find(o => o.getTag() == option.getTag())?.getValues().find(v => v.getValue() == currentOptionValue);
          if (currentValue != undefined) {
            this.minTotalPax -= currentValue.getMinTotalPax();
          }
        }
        this.selectedOptions.set(option.getTag(), value.getValue());
        this.minTotalPax += value.getMinTotalPax();
      }
    }
  }

  protected isValueSelected(option, value): boolean {
    const selectedValue = this.selectedOptions.get(option.getTag());
    return selectedValue != undefined && selectedValue == value.getValue();
  }

  protected name: string = '';
  private nameRegex = /.{3,25}/;
  protected nameValid(): boolean {
    return (this.name.trim().match(this.nameRegex)) ? true : false;
  };

  protected lastname: string = '';
  private lastnameRegex = /.{3,50}/;
  protected lastnameValid(): boolean {
    return (this.lastname.trim().match(this.lastnameRegex)) ? true : false;
  };

  protected email: string = '';
  protected closestDomain: string = '';
  private emailRegex = /[-\w\.]+@([-\w]+\.)+[-\w]{2,5}/;
  protected emailValid(): boolean {
    return (this.email.trim().match(this.emailRegex)) ? true : false;
  };
  protected emailTypo(): boolean {
    const email = this.email.trim();
    const domain = email.substring(email.indexOf('@') + 1).toLowerCase();
    const [closest, distance] = StringUtils.closestMatch(domain, ['gmail.com', 'outlook.com', 'hotmail.com', 'yahoo.com']);
    if (distance > 0 && distance <= 2) {
      this.closestDomain = closest;
      return true;
    }
    this.closestDomain = '';
    return false;
  }

  protected prefixRegex = /^[34]\d+$/; // European prefix only
  protected phone: string = '+34';     // Default to Spain prefix
  protected phoneError: boolean = false;
  protected validLengths: string = '';
  protected phoneValid(): boolean {
    return !this.phoneError;
  };

  protected comments: string = '';
  private replaceComments: string;
  
  protected getMinTotal(): number {
    return this.minTotalPax * this._size;
  }

  private getMaxSize(o: HourlyOccupation): number {
    return Math.max(...o.getHours().slice(0, -1).map(h => o.getCapacity(h).length > 1 ? o.getCapacity(h)[1] : 0));
  }

  private getMaxDuration(date: string, hour: number, size: number): number {
    // if today and earlier than now, no duration
    if (date == DateUtils.getTodayString() && hour < DateUtils.getNow()) {
      return 0;
    }
    const capacity = this.selectedHourlyOccupation.getCapacity(hour);
    const index = capacity.findIndex(c => c < size);
    const maxDuration = (index >= 0 ? index : capacity.length == 0 || capacity[0] < size ? 0 : capacity.length) * this.interval;
    return (maxDuration >= 1 - Number.EPSILON) ? maxDuration : 0;
  }

  protected isZoneValid(): boolean {
    return this._selectedZone != null;
  }

  protected isReserveDataValid(): boolean {
    return this._date != null && this.size != null && this._start != null && this._duration != null;
  }

  protected areOptionsValid(): boolean {
    let optionsValid = true;
    this.getOptions().filter(o => o.isRequired()).forEach(o => optionsValid &&= this.selectedOptions.has(o.getTag()));
    return optionsValid;
  }

  protected isMinTotalAccepted(): boolean {
    return (this.getMinTotal() < Number.EPSILON) || this.minTotalAccepted;
  }

  protected isUserDataValid(): boolean {
    return this.nameValid() && this.lastnameValid() && this.emailValid() && this.phoneValid();
  }

  protected isValid(): boolean {
    return this.isZoneValid() && this.isReserveDataValid() && this.areOptionsValid() && this.isMinTotalAccepted() && this.isUserDataValid() ;
  }

  protected requestReserve() {
    if (this.isValid()) {
      const email = this.email.trim();
      const phone = this.phone.replace(/[^\+\d]/g, '');
      const minTotal = this.getMinTotal();
      const options = Object.fromEntries(this.selectedOptions);
      const attributes = JSON.stringify({...options, minTotal: minTotal > 0 ? minTotal : undefined, name: this.name.trim() + ' ' + this.lastname.trim(), email: email, phone: phone, comments: this.comments});
      this.ws.requestReserve(this._selectedZone.id, this._date, this._start, this._duration, this._size, attributes, this.urlCode,
        _ => {
          const tag = 'USER_RA_SUCCESS_' + (this.urlCode ? 'UPDATED' : this.loggedIn ? 'CONFIRMED' : 'REQUESTED');
          DialogComponent.showInfoDialog(this.getTranslation(tag + '_TITLE'), this.getTranslation(tag + '_MSG'))
            .then(_ => this.urlCode ? this.goToUser() : null);
          this.clear();
        },
        () => DialogComponent.showError(this.getTranslation('USER_RA_UNKNOWN_ERR'))
      );
    }
  }

  private goToUser() {
    window.parent.postMessage({'url': './user'}, '*');
  }

  private showBenefits() {
    DialogComponent.showInfoDialog(this.getTranslation('USER_RA_BENEFITS_TITLE'), this.getTranslation('USER_RA_BENEFITS_MSG'), "big");
  }

  private clear() {
    this.selectedZone = null;
    this.date = null;
    this.size = null;
    this.start = null;
    this.duration = null;
    this.comments = '';
    this.selectedOptions.clear();
    this.selectedHourlyOccupation = null;
    this.minTotalPax = 0;
    this.ref = Date.now();
    if (this.loggedIn == false) {
      this.name = '';
      this.lastname = '';
      this.email = '';
      this.phone = '';
    }
  }

  private getOccupationSymbols(level: string) {
    switch (level) {
      case 'LOW':
        return '\u25cf\u25cb\u25cb\u25cb';
      case 'MEDIUM':
        return '\u25cf\u25cf\u25cb\u25cb';
      case 'HIGH':
        return '\u25cf\u25cf\u25cf\u25cb';
      default:
        return this.getTranslation('USER_RA_CLOSED');
    }
  }

  protected onInnerHtmlClick(event: Event) {
    if (event.target instanceof HTMLElement) {
      switch (event.target.className) {
      case "f_users":
        this.goToUser();
        break;
      case "f_benefits":
        this.showBenefits();
        break;
      }
    }
  }

  protected getTranslation(tag: string, params: any = {}): string {
    return this.ws.translate(tag, params);
  }
}
