import { Timetable } from "./timetable";
import { DateUtils } from "../../shared/utils/dateUtils";

class HourlyOccupationItem {
  available: number;
  maxSizes: number[];

  constructor(json: any[], zoneId: number) {
    this.available = +json['available'];
    
    this.maxSizes = [];
    if ('maxSizes' in json) {
      const maxSizes:Object = json['maxSizes']; 
      if (zoneId in maxSizes) {
        for (const size of maxSizes[zoneId]) {
          this.maxSizes.push(+size);
        }
      }
    }
  }
}

export class ReserveOptionValue {
  private name: string;
  private description: string;
  private value: number;
  private errorLevel: number;
  private warnLevel: number;
  private level: number;
  private start: number;
  private end: number;
  private minTotalPax: number;

  constructor(json: any[]) {
    this.name = json["name"];
    this.description = json["description"];
    this.value = +json["value"];
    this.errorLevel = +json["errorLevel"];
    this.warnLevel = +json["warnLevel"];
    this.level = +json["level"];
    this.start = +json["start"];
    this.end = +json["end"];
    this.minTotalPax = +json["minTotalPax"];
  }

  public getName(): string {
    return this.name;
  }

  public getDescription(): string {
    return this.description;
  }

  public getValue(): number {
    return this.value;
  }

  public getLevel(): number {
    return this.level;
  }

  public getStatus(): string {
    return this.level >= this.errorLevel ? "error" : (this.level >= this.warnLevel ? "warn" : "ok");
  }

  public getStart(): number {
    return this.start;
  }

  public getEnd(): number {
    return this.end;
  }

  public getMinTotalPax(): number {
    return this.minTotalPax;
  }
}

export class ReserveOption {
  private name: string;
  private tag: string;
  private required: boolean;
  private start: number;
  private end: number;
  private values: ReserveOptionValue[];

  constructor(json: any[]) {
    this.name = json["name"];
    this.tag = json["tag"];
    this.required = json["required"] == "1";
    this.start = +json["start"];
    this.end = +json["end"];
    this.values = [];
    for (const value of Object.keys(json['values'])) {
      this.values.push(new ReserveOptionValue(json['values'][value]));
    } 
  }
  
  public getName(): string {
    return this.name;
  }

  public getTag(): string {
    return this.tag;
  }

  public isRequired(): boolean {
    return this.required;
  }

  public getStart(): number {
    return this.start;
  }

  public getEnd(): number {
    return this.end;
  }

  public getValues(): ReserveOptionValue[] {
    return this.values;
  }
}

export class HourlyOccupation {
  private zoneId: number;
  private timetable: Timetable;
  private occupation: Map<number, HourlyOccupationItem>;
  private options: ReserveOption[];

  constructor(json: any[]) {
    this.timetable = new Timetable(json['timetable']);
    this.occupation = new Map();
    for (const hour of Object.keys(json['occupation'])) {
      this.zoneId = +Object.keys(json['occupation'][hour]['maxSizes'])[0];
      this.occupation.set(DateUtils.parseTime(hour), new HourlyOccupationItem(json['occupation'][hour], this.zoneId));
    }
    this.options = [];
    for (const option of Object.keys(json['options'])) {
      this.options.push(new ReserveOption(json['options'][option]));
    }
  }

  public getHours(): number[] {
    return Array.from(this.occupation.keys());
  }

  public getCapacity(hour: number): number[] {
    return this.occupation.has(hour) ? this.occupation.get(hour).maxSizes : [];
  }

  public checkCapacity(hour: number, duration: number, size: number): boolean {
    const capacity = this.getCapacity(hour);
    // TODO: get duration resolution from data
    const index = Math.round((duration - 0.5) * 2);
    return capacity.length > index && capacity[index] >= size;
  }

  public getTimetable(): Timetable {
    return this.timetable;
  }

  public getOptions(): ReserveOption[] {
    return this.options;
  }
};

class DailyOccupationItem {
  open: number;
  close: number;
  dayType: string;
  level: string;

  constructor(json: any[]) {
    this.open = +json['open'];
    this.close = +json['close'];
    this.dayType = json['type'];
    this.level = json['enum'];
  }
}

export class DailyOccupation {
  private occupation: Map<string, DailyOccupationItem>;
  
  constructor(json: any[]) {
    this.occupation = new Map();
    for (const date of Object.keys(json)) {
      this.occupation.set(date, new DailyOccupationItem(json[date]));
    }
  }

  public getDates(): string[] {
    return Array.from(this.occupation.keys());
  }

  public getOccupation(date: string): DailyOccupationItem {
    return this.occupation.get(date);
  }
}